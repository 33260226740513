.header-container-f1{
    position: relative;
}
.header-container-f1 h2{
    border-bottom: 2px solid black;
    padding-bottom: 5px;
    padding-left: 30px;
}
.header-container-f1 img{
    position: absolute;
    top: 10px; 
    right: 30px;
    border: 10px solid gray;
    width: 100px;
    height: 100px;
}
.user-info {
    margin-top: -10px;
}
.user-info div{
color: black;
margin-left: 30px !important;
font-size: 15px;

}
.description-container{
    margin: 15px 0px 0px 24px;
    font-size: 14px;
    font-weight:600;
    font-style: italic;
}
.education .left{
border-right: 2px solid gray;
}
.education .left h4{
    margin-right:30px;
  
}
ul{
    /* list-style-type: circle; */
    list-style-type: disc;
}