
.navbar-mobile-hide{
    width: 300px !important;
    height: 100%;
    position: fixed !important;
    top: 0px ;
    left: -300px;
    background-color: #1d2951;
    transition: all 0.5s ease-in-out;
    z-index: 999;
    padding:10px;
    /* display: none; */
}

.navbar-mobile-view{
    width: 300px;
    height: 100%;
    position: fixed;
    top: 0px ;
    left: 0px;
    background-color: #1d2951;
    display: "flex";
    flex-direction: column;
    z-index: 999;
    color: white !important;
    transition: all 0.5s ease-in-out;
    padding: 10px;
}
.links-container{
    padding: 5px 10px;
    color:white;
}
.links-container a{
    color:white;
}
.all-links{
    padding:40px 0px
}