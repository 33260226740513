.header-container{
    box-shadow: 5px 5px 0px gray;
    border-radius: 10px;
    margin: 10px;
   
}

.header-container1{
    box-shadow: 5px 5px 0px gray;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin: 10px;
   
}

.container-h5 h5{
width: 300px;
border-top-left-radius: 15px;
border-top-right-radius: 15px;
background-color: blue;
color: white;
text-align: center;
padding: 1px;
margin-left: 10px;
}