/*** 
====================================================================
  Table of contents
====================================================================

- Google Fonts
- Css Imports
- General Css Styles
- Header Area style
- Welcome Area Styles 
- Partners area style
- About us area style 
- services-block-four style 
- Demo-video area style 
- Trust area style 
- services area style 
- Call-to-action area style
- Video area style 
- Gallery area style 
- Cool-facts style 
- Price table style
- Testimonials area style 
- Team area style
- Blog area style 
- Contact us style 
- FAQ style -Timeline style 
- Footer area style 

***/

/*** 

====================================================================
  Google fonts
====================================================================

***/

@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700');

/*** 

====================================================================
  Import All Css
====================================================================

***/

@import 'bootstrap.min.css';
@import 'animate.css';
@import 'about-sonar.css';
@import 'classy-nav.min.css';
@import 'dzsparallaxer.css';
@import 'owl.carousel.min.css';
@import 'magnific-popup.css';
@import 'animated-headline.css';
@import 'font-awesome.min.css';
@import 'themify-icons.css';

/*** 

====================================================================
  General css style
====================================================================

***/

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
} 
.other-accounts{
    background: linear-gradient(to right, #21d397 0%, #7450fe 100%);
    margin-top: 30px;
    padding: 20px
}
.other-accounts .footer-social-info a i{
    font-size: 14px;
    background: #fff;
    color: #197bb5;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    line-height: 30px;
    border-radius: 50%;
}
* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;
}
.darker-blue{
    background: linear-gradient(180deg,#240044 0,#0f0240 25%,#400959 40%,#0f0240 65%,#0f0240);
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    line-height: 1.4;
    font-weight: 500;
}
.clear-fix{clear: both}
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    outline: none;
}

li {
    list-style: none;
}

p {
    line-height: 1.9;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
}

img {
    max-width: 100%;
    height: auto;
}

ul,
ol {
    margin: 0;
    padding: 0;
}
.section-header{
    position: relative;
    margin-bottom: 50px
}
.section-title {
    font-size: 44px;
    margin-bottom: 20px;
    margin-top: 0;
    position: relative;
    text-align: center;
    color: #fff;
}
.section-header .desc{
    max-width: 800px;
    text-align: center;
    margin: 0 auto
}
.bg-title {
    margin-bottom: -37px;
    margin-left: -30px;
    font-size: 66px;
    opacity: .04;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    text-transform: uppercase;
}
.has-print{
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 90px;
    /* background: url(../img/core-img/shape1.png) no-repeat; */
}
#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    top: 0;
    left: 0;
    background: #4834d4;
    background: -webkit-linear-gradient(to right, #4834d4, #341f97);
    background: -webkit-linear-gradient(left, #4834d4, #341f97);
    background: linear-gradient(to right, #4834d4, #341f97);
}
.header-area .light.classy-nav-container a {
    color: #fff;
}
.header-area.sticky .light.classy-nav-container a {
    color: rgb(19 40 126);
}

.header-area .d.classy-nav-container a {
    color: #13287e;
}
.header-area.sticky .d.classy-nav-container a {
    color: rgba(255,255,255,.7);
}
.preload-content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -35px;
    margin-left: -35px;
    z-index: 100;
}

#dream-load {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 3px;
    border-style: solid;
    border-color: transparent;
    border-top-color: rgba(255, 255, 255, 0.5);
    -webkit-animation: dreamrotate 2400ms linear infinite;
    animation: dreamrotate 2400ms linear infinite;
    z-index: 999;
}

#dream-load:before {
    content: "";
    position: absolute;
    top: 7.5px;
    left: 7.5px;
    right: 7.5px;
    bottom: 7.5px;
    border-radius: 50%;
    border: 3px;
    border-style: solid;
    border-color: transparent;
    border-top-color: rgba(255, 255, 255, 0.5);
    -webkit-animation: dreamrotate 2700ms linear infinite;
    animation: dreamrotate 2700ms linear infinite
}

#dream-load:after {
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    border: 3px;
    border-style: solid;
    border-color: transparent;
    border-top-color: rgba(255, 255, 255, 0.5);
    -webkit-animation: dreamrotate 1800ms linear infinite;
    animation: dreamrotate 1800ms linear infinite
}

@-webkit-keyframes dreamrotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes dreamrotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

/* Blip CSS */

.dream-blip {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 2em 2em 2em 2em;
    background-color: #21d397;
    box-shadow: 0 0 5px #fff;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-animation: dreamblipper ease 4s none infinite;
            animation: dreamblipper ease 4s none infinite;
}

@-webkit-keyframes dreamblipper {
    0% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    35% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    100% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

@keyframes dreamblipper {
    0% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    35% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    50% {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    100% {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

.dream-blip.blip1 {
    top: 20%;
    left: 20%;
}

.dream-blip.blip2 {
    top: 70%;
    left: 30%;
}

.dream-blip.blip3 {
    top: 30%;
    left: 10%;
}

.dream-blip.blip4 {
    top: 60%;
    left: 10%;
}
.mt-10 {
    margin-top: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-100 {
    margin-right: 100px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-100 {
    margin-left: 100px;
}

.section-padding-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.section-before:before{
    content: '';
    position: absolute;
    top: -75px;
    left: 50%;
    transform: translateX(-50%);
    height: 150px;
    width: 150px;
    /* background: url(../img/core-img/shape1.png) no-repeat; */
    background-size: contain;
}
.relative{
    position: relative;
}
.section-padding-0-100 {
    padding-top: 0;
    padding-bottom: 100px;
}

.section-padding-100-0 {
    padding-top: 100px;
    padding-bottom: 0;
}
.section-padding-100-85 {
    padding-top: 100px;
    padding-bottom: 85px;
}
.section-padding-70-0 {
    padding-top: 70px;
    padding-bottom: 0;
}
.section-padding-0-70 {
    padding-top: 0px;
    padding-bottom: 70px;
}
.section-padding-0-0{
    padding-top: 0;
    padding-bottom: 0
}
.section-padding-100-50 {
    padding-top: 100px;
    padding-bottom: 50px;
}
.section-padding-100-70 {
    padding-top: 100px;
    padding-bottom: 70px;
}
.section-padding-0-50 {
    padding-top: 0;
    padding-bottom: 50px;
}

.section-padding-50-0 {
    padding-top: 50px;
    padding-bottom: 0;
}

.section-padding-200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.section-padding-0-200 {
    padding-top: 0;
    padding-bottom: 200px;
}

.section-padding-200-0 {
    padding-top: 200px;
    padding-bottom: 0;
}

.section-padding-200-100 {
    padding-top: 200px;
    padding-bottom: 100px;
}

.section-padding-150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.section-padding-150-0 {
    padding-top: 150px;
    padding-bottom: 0;
}

.section-padding-0-150 {
    padding-top: 0;
    padding-bottom: 150px;
}

.section-padding-50-150 {
    padding-top: 50px;
    padding-bottom: 150px;
}

.section-heading {
    position: relative;
    z-index: 1;
    margin-bottom: 80px;
}

.section-heading h2 {
    font-size: 30px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.section-heading > p {
    margin-bottom: 0;
    line-height: 2;
    font-size: 16px;
    max-width: 600px;
    margin: 0 auto;
    color: #878484;

}

#scrollUp {
    bottom: 130px;
    font-size: 12px;
    line-height: 22px;
    right: 30px;
    width: 100px;
    background-color: transparent;
    color: #fff;
    text-align: center;
    height: 20px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    text-transform: uppercase;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

#scrollUp:before {
    position: absolute;
    width: 30%;
    height: 2px;
    background-color: #fff;
    content: "";
    top: 10px;
    right: 100%;
    z-index: -200 !important;
}

.bg-overlay {
    position: relative;
    z-index: 1;
}

.bg-overlay:after {
    background: rgba(72, 52, 212, 0.95);
    background: -webkit-linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: -webkit-linear-gradient(left, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.bg-img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.height-400 {
    height: 400px;
}

.height-600 {
    height: 600px;
}

.height-700 {
    height: 700px;
}

.height-800 {
    height: 800px;
}

.height-900 {
    height: 900px;
}

.height-1000 {
    height: 1000px;
}

.dream-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}

.dream-dots span {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 5px;
}

.dream-dots span:first-child {
    background-color: #00a8ff;
}

.dream-dots span:nth-child(2) {
    background-color: #9c88ff;
}

.dream-dots span:nth-child(3) {
    background-color: #fbc531;
}

.dream-dots span:nth-child(4) {
    background-color: #4cd137;
}

.dream-dots span:nth-child(5) {
    background-color: #487eb0;
}

.dream-dots span:nth-child(6) {
    background-color: #e84118;
}

.dream-dots span:nth-child(7) {
    background-color: #8c7ae6;
}

.login-btn {
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 12px !important;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 33px;
    padding: 0 20px;
    min-width: 100px;
    color: #fff !important;
    background: rgb(62, 148, 228);
    height: 35px;
    border-radius: 5px;
    border: 1px solid #fff !important;
    letter-spacing: 1px;
}

.login-btn:hover,
.login-btn:focus {
    color: #fff;
    background: #7450fe;
    border-color: #7450fe;
}


.dream-btn {
    position: relative;
    z-index: 1;
    min-width: 160px;
    height: 48px;
    line-height: 48px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    display: inline-block;
    padding: 0 20px;
    text-align: center;
    text-transform: uppercase;
    background-size: 200% auto;
    color: #fff;
    box-shadow: 0 3ox 20px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    background:rgb(62, 148, 228);
    -webkit-transition: all 500ms;
    transition: all 500ms;
}

.dream-btn:hover {
    background-position: right center;
    color: #fff;
}
.dream-btn.green-btn {
    background:rgb(62, 148, 228)
}
.more-btn.pink {
    background: #F69;
    border: 0;
    box-shadow: 0 20px 43px 0 rgba(216,58,131,.32);
}
.more-btn {
    position: relative;
    z-index: 1;
    min-width: 160px;
    height: 48px;
    line-height: 48px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    display: inline-block;
    padding: 0 20px;
    text-align: center;
    text-transform: uppercase;
    background-size: 200% auto;
    color: #fff;
    box-shadow: 0 3ox 20px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    background-image: linear-gradient(to right, #21d397 0%, #7450fe 50%, #21d397 100%);
    background-image: -webkit-linear-gradient(to right, #21d397 0%, #7450fe 50%, #21d397 100%);
    border: 1px solid #fff;
    -webkit-transition: all 500ms;
    transition: all 500ms;
}
/* ##### 2.0 Header Area CSS ##### */

.header-area {
    /* position: fixed; */
    /* z-index: 5000; */
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.header-area.sticky {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    background-color: #ccd7e8;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    border-bottom: none;
}

.dark.classy-nav-container {
    background-color: transparent;
}

.classy-navbar {
    height: 90px;
    padding: 0;
}

.classynav ul li a  {
    text-transform: uppercase;
}

.classynav ul li .megamenu li a {
    font-size: 80%;
}
.side-feature-list-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
}
.check-mark-icon {
    margin-right: 16px;
    width: 30px;
    height: 30px
}
.foot-c-info {
    font-weight: 500;
}
.call-us-sec{
    background: #1717e552;
    padding: 50px 30px;
}
/* ##### 3.0 Wellcome Area CSS ##### */

.welcome_area {
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 700px !important;
}

.flex{
    display: flex
}
.hero-content.globe{
    background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.75));
}
.hero-content.transparent{
    background: transparent;
}
.welcome_area.demo1{
    /* background: url(../img/bg-img/demo1-bg.png) #fff; */
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
}
.welcome_area.demo2{
    background: #fff;
}
.welcome_area.demo3{
    /* background: url(../img/bg-img/demo3-bg.png) no-repeat top center; */
    background-size: cover;
}
.welcome_area.demo4{
    /* background: url(../img/bg-img/demo4-bg.png) no-repeat top center; */
    background-size: cover;
}
.welcome_area.demo5{
    /* background: url(../img/bg-img/demo5-bg.png) no-repeat top center; */
    background-size: cover;
}
.welcome_area.demo6{
    /* background: url(../img/bg-img/demo6-bg.png) no-repeat top center; */
    background-size: cover;
}
.hero-content.dark-blue{
    background: rgb(33, 0, 67, 0.9);
}
.hero-content .ico-counter{
    margin: 100px 7% 0;
}
@media (max-width: 992px){
    .hero-content .ico-counter,
    .hero-content .service-img-wrapper .image-box{
        margin-top: 0px !important
    }
    .welcome_area.demo3,
    .welcome_area.demo5,
    .welcome_area.demo6{
        background-position: top left
    }
}
.hero-content .service-img-wrapper .image-box .rings{
    position: absolute;
    top: 50%;
    z-index: -1;
    margin: 0 auto;
    left: 50% !important;
    width: 120%;
    transform: translate(-50%, -50%);
    animation: unset;
    -webkit-animation: unset;
}
.welcome-content {
    position: relative;
    z-index: 1;
    margin-top: 90px;
}

.promo-section {
    margin-bottom: 30px;
}
.integration-link {
    padding: 8px 10px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    letter-spacing: .58px;
    font-size: 14px;
    line-height: 24px;
    color: #d5d5ea
}
.integration-link:hover {
    color: #fff;
    text-decoration: none;
}
.integration-link:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to bottom right,#766ee6 0,#3540b0);
    border-radius: 100px;
    opacity: .5;
}
.integration-link.light:before{
    background: #e8f3fd;
    opacity: 1;
}
.integration-link.light .integration-text{
    color: #3e94e4;
    font-weight: 600;
}
.integration-link.full:before{
    opacity: 1
}
.integration-icon {
    margin: 0 5px 0 0;
}
.integration-text {
    margin: 0 7px 0 0;
}
.integration-link>* {
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: top;
}

.main-ilustration{
    position: relative;
    /* background: url(../img/core-img/hero-bg.png) no-repeat center 65%; */
    height: 100vh;
    background-size: contain;
}
.main-ilustration-2{
    position: relative;
    /* background: url(../img/core-img/robot-1.png) no-repeat center bottom; */
    height: 100vh;
    bottom: 0;
    background-size: contain;
}
.main-ilustration-3{
    position: relative;
    /* background: url(../img/core-img/robot-2.png) no-repeat center bottom; */
    height: 100vh;
    bottom: 0;
    background-size: cover;
}
.main-ilustration-4{
    position: relative;
    /* background: url(../img/svg/head-bg-1.svg) no-repeat center 65%; */
    height: 100vh;
    background-size: cover;
}
@media (max-width: 1200px){
    .main-ilustration-3{
        background-size: contain;
        background-position: left;
    }
    .main-ilustration-3 .inovation{
        right: 0% !important;
        top: 5%;
        display: inline-table;
    }
    .main-ilustration-3 .inovation.num2{
        right: 5%;
        top: 27%;
    }
}
@media (max-width: 767px){
    .main-ilustration-3{
        background-size: contain;
        background-position: left;
    }
    .main-ilustration-3 .inovation{
        right: 0% !important;
        top: 42%;
        display: inline-table;
    }
    .main-ilustration-3 .inovation.num2{
        right: 5%;
        top: auto;
    }
    .welcome-small-wrapper{
        position: absolute;
        width: 38%;
    }
    .welcome-content-small{
        position: absolute;
        top: -30px;
    }
    .welcome-content.ill{
        margin-top: 120px !important
    }
}
@media (max-width: 480px){

    .welcome-content {
        vertical-align: middle;
        margin-top: 100px !important

    }
    .welcome-content h1{
        font-size: 27px !important
    }
    .main-ilustration-3{
        display: none;
    }
    .welcome-small-wrapper{
       display: none;
    }
    
}
.main-ilustration-2:before{
    content: '';
    position: absolute;
    width: 30%;
    height: 1000px;
    left: 50%;
    top: -30%;
    transform: rotate(21deg);
    background: rgba(0, 0, 128, 0.7);
}
.main-ilustration-3 .inovation{
    position: absolute;
    bottom: 10%;
    right: -15%;
    width: 40%;
    background: #0d003b;
    padding: 20px;
}
.inovation.num2{
    right: -57%;
}
.inovation h5{
    padding-left: 25px;
    position: relative;
}
.inovation h5:before{
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    width: 15px;
    height: 2px;
    background: #25cbd3;
}
.inovation p{
    font-size: 12px;
    margin-bottom: 0;
}


@media (min-width: 1200px){
    .main-ilustration {
        min-width: 700px;
        margin-top: 25px;
        margin-left: -100px
    }
    .scrol-icon {
        position: absolute !important;
        width: 30px;
        height: 60px;
        z-index: 10;
        margin: 0 auto;
        margin-top: 0px;
        cursor: pointer;
        border-radius: 50px;
        border: 2px solid cyan;
        background-image: -webkit-linear-gradient(#766ee6 0%, #3540b0);
        background-image: -o-linear-gradient(#766ee6 0%, #3540b0);
        background-image: linear-gradient(#766ee6 0%, #3540b0);
        transform: translateX(-50%);
        bottom: 50px;
        left: 50%;
        -webkit-animation: shake 0.5s infinite  alternate;
        animation: shake 0.5s infinite alternate;
    }
    .scrol-icon span {
        position: absolute;
        left: 50%;
        top: 12px;
        width: 2px;
        height: 26px;
        margin-left: -1px;
        background-color: #fff;
    }
    .scrol-icon span::before {
        position: absolute;
        content: '';
        left: 50%;
        top: 100%;
        width: 2px;
        height: 3px;
        margin-top: 4px;
        margin-left: -1px;
        background-color: #fff;
    }
}
@-webkit-keyframes shake {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

@keyframes shake {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
/* ##### 3.0 Wellcome Area CSS ##### */
.hero-section{
    position: relative;
    min-height: 700px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-section .welcome-content h1{
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
}
.hero-section .c-facts-box{
    margin-top: 160px;
    margin-bottom: 100px;
    padding-bottom: 50px
}
.cd-intro.v2 {
    margin: 0em auto;
}
.cd-intro.v2 .cd-headline.clip span{
    display: inline;
    padding: 0 !important;
}
.cd-intro.v2 .cd-headline.clip .cd-words-wrapper {
    vertical-align: middle;
}

.promo-section {
    margin-bottom: 30px;
}
.promo-section.bg{
    background: #ff966a;
    padding: 10px 20px;
    display: inline-block;

}
.promo-section.bg2{
    background: #cb202d;
    padding: 10px 20px;
    display: inline-block;

}

.promo-section.bg .special-head{
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0;
    
    position: relative;
    letter-spacing: 1px;
}
.special-head{position: relative;font-size: 18px;padding-left: 40px}
.special-head:before {
    content: '';
    background: #05ffff;
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 2px;
}
.gradient-text {
    font-weight: bold;
    display: inline-block;
    background-image: linear-gradient(135deg, #846FF4 0%, #F17674 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.gradient-text.cyan {
    background-image: linear-gradient(135deg, cyan 0%, cyan 100%) !important;
}
.i-bg1 {
    /* background-image: url(../img/bg-img/header-bg1.png); */
    background-repeat: no-repeat;
    background-size: cover;
}
.i-bg2 {
    /* background-image: url(../img/bg-img/illustration.png); */
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
}
.i-bg3 {
    /* background-image: url(../img/bg-img/header-bg3.png); */
    background-repeat: no-repeat;
    background-size: cover;
}
.i-bg4 {
    /* background-image: url(../img/bg-img/header-bg4.png); */
    background-repeat: no-repeat;
    background-size: cover;
}
.i-bg5 {
    /* background-image: url(../img/bg-img/header-bg5.png); */
    background-repeat: no-repeat;
    background-size: cover;
}
.welcome-content h2 {
    font-size: 52px;
    font-weight: 500;
    margin-bottom: 20px;
}
.welcome-content h1 {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 20px;
}
.welcome-content h1.artficial{
    font-size: 38px
}
.welcome-content p {
    font-size: 18px;
    color: #878484;
    margin-bottom: 30px;
} 

p.artficial{
    padding-left: 20px;
    border-left: 1px solid #fff;
    position: relative;
}
p.artficial:before{
    content: '';
    position: absolute;
    left: -5px;
    top: -8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #fff
}
.welcome-content-small{
    margin-bottom: 50px
}
.welcome-content-small h4{
    margin-bottom: 20px
}
.welcome-content-small p.artficial{
    font-size: 12px
}
.welcome-video-area {
    position: relative;
    z-index: 1;
    margin-top: 90px;
}

.welcome-video-area .welcome-thumb {
    position: relative;
    z-index: 1;
    margin-top: 30px;
    border: 1px solid #25cbd3;
    border-radius: 15px;
    padding: 0px 30px 0;
}

.welcome-video-area .welcome-thumb img {
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
    position: relative;
    top: -30px
}

.video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 40px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
    color: #4834d4;
}

.video-btn {
    background-color: #4834d4;
    width: 70px;
    height: 70px;
    color: #fff;
    border-radius: 50%;
    line-height: 70px;
    padding: 0;
    border: 2px solid #fff;
    text-align: center;
    min-width: 70px;
    font-size: 24px;
}

.video-btn.v2{
    width: 50px;
    height: 50px;
    line-height: 50px;
    min-width: 50px;
    font-size: 16px;
}
.video-btn:hover {
    background-color: #4834d4;
    color: #fff;
}




.fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 1200px){
    .sity {
        max-width: 142%;
        margin-left: -100px;
        margin-top: 50px;
        max-width: 150%;
    }
    .banner-box{
        margin-right: -100px;
        margin-top: 40px
    }
    .banner-box.v2{
        margin-left: -50px;
        margin-top: 100px
    }
    .brain{
        position: absolute;
        top: 0;
        left: 25%;
        width: 140px;
        animation: floating2 7s infinite;
        -webkit-animation: floating2 7s infinite;
    }
    .i-circled {
        width: 400px;
        height: 400px;
        position: absolute;
        top: 0;
        left: 0;
        border: 70px solid #2bd6ff;
        border-radius: 50%;
        opacity: 0.2;
        animation: floating2 7s infinite;
        -webkit-animation: floating2 7s infinite;
    }
}
@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}
.welcome_area.video .desc{
    max-width: 60%;
    margin: 0 auto;
    font-size: 16px
}
@media (max-width: 767px) {
  .fullscreen-bg {
    /* background: url('video/video-frame.PNG') center center / cover no-repeat; */
  }
   .welcome_area.video .desc{
        max-width: 80%;
        margin: 0 auto
    }
  .fullscreen-bg__video {
    display: none;
  }
}
.video-bg-illution{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #010111;
    z-index: 50;
    opacity: .7;
}

/* ##### Partners Area ##### */
.partners{
    padding: 0 0 70px
}
.partner-box{
    border: 1px solid #25cbd3;
    border-radius: 10px;
    padding: 20px 10px;
    text-align: center;
    vertical-align: middle;
    background-color: #0d003b;
    margin-bottom: 30px;
    transition: all .3s ease-in-out;
}
.partner-box:hover{
    background: #25cbd3;
    cursor: pointer;
}

/* ##### About Us Area ##### */

.about-us-area {
    position: relative;
    z-index: 1;
}

.who-we-contant h4 {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 600
}
.who-we-contant p {
    color:#878484
}



.our-mission-area {
    position: relative;
    z-index: 10;
    width: 100%;
    overflow: hidden;
    height: 400px !important;
}

.our-mission-content {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 100px 0 70px;
    z-index: 10;
    background: rgba(72, 52, 212, 0.95);
    background: -webkit-linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: -webkit-linear-gradient(left, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
}

.single-mission {
    position: relative;
    z-index: 1;
    text-align: center;
    margin-bottom: 30px
}

.single-mission i {
    font-size: 50px;
    margin-bottom: 30px;
    display: block;
    color: #fff;
}

.single-mission h6 {
    margin-bottom: 15px;
}

.single-mission p {
    margin-top: 0;
    margin-bottom: 0
}

.welcome-meter {
    position: relative;
    z-index: 1;
}
.welcome-meter.arficial{
    position: relative;
    z-index: 1;
    height: 427px;
    /* background: url(../img/svg/ai-illustration.svg) no-repeat; */
    background-size: 137%;
    background-position: center center;
}
@media (max-width: 992px){
    .welcome-meter.arficial{
        background-size: 100%;
    }
    .welcome-content.special{
        margin-top: 190px
    }
}
.growing-company p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
}

.growing-company p .counter {
    color: #fff;
}
/*** 

====================================================================
  services-block-four style
====================================================================

***/
.features{
    position: relative;
}
.services-block-four{
  position:relative;
  margin-bottom:50px;
}

.services-block-four .inner-box{
  position: relative;
    padding-left: 120px;
}
.licenes .services-block-four .inner-box .icon-box{
    font-size: 56px;
    border-radius: 0%
}
.licenes .services-block-four .inner-box .icon-box:after{
    border-radius: 0%
}
.services-block-four .inner-box .icon-box:after, .services-block-four .inner-box .icon-box:before{
    background: #25cbd3
}
.services-block-four .inner-box .icon-box{
  position:absolute;
  left:0px;
  top:0px;
  width:100px;
  height:100px;
  border: 2px solid;
  font-size:40px;
  line-height:98px;
  text-align:center;
  border-radius:50%;
  margin-bottom:20px !important;
  transition: .3s ease;
}
.service-img-wrapper .image-box{
    position: relative;
}
@media (max-width: 1200px){
    .service-img-wrapper .image-box .rings{
        left: 3% !important;
    }
    .service-img-wrapper .image-box{
        margin-top: 0 !important
    }
}
@media (max-width: 992px){
    .service-img-wrapper .image-box{
        margin-top: 30px !important
    }
    .service-img-wrapper .phone-img{
        padding: 0 20% !important
    }
    .service-img-wrapper .image-box .rings {
        left: 17% !important;
        width: 66%;
    }
}

.service-img-wrapper .image-box .rings{
    position: absolute;
    top: 5%;
    z-index: -1;
    margin: 0 auto;
    left: 10%;
    transform: translate(-50%, -50%);
    -webkit-animation: rotate-m 4s linear infinite;
     -o-animation: rotate-m 4s linear infinite;
     animation: rotate-m 4s linear infinite;
}
.services-block-four .inner-box .icon-box span {
  position: relative;
  z-index: 99;
}

.services-block-four .inner-box:hover .icon-box,
.services-block-four .inner-box:hover .icon-box span {
    color: #fff;
    transition: .5s ease;
}

.services-block-four .inner-box .icon-box:after {
  position:absolute;
  content:'';
  left:0;
  top:0;
  width:100%;
  height:100%;
  border-radius: 50%;
  transform: scale(0);
  transition: .7s ease;
  
}
.services-block-four .inner-box:hover .icon-box:after {
  transform: scale(1);
  transition: .7s ease;
}

.services-block-four .inner-box .icon-box:before{
  position:absolute;
  content:'';
  left:50%;
  top:100%;
  width:1px;
  height:95%;
  background: #25cbd3
}

.services-block-four:last-child .inner-box .icon-box:before{
  display:none;
}

.services-block-four .inner-box h3{
  position:relative;
  font-size:18px;
  font-weight:600;
  text-transform:capitalize;
}

.services-block-four.how .inner-box{
    padding-left: 70px;
    position: relative;
}
.services-block-four.how .inner-box .step{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
    border: none;
    background: #ffffff;
    border-color: #7d60f9;
    font-size: 18px;
    color: #13287e ;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    margin-bottom: 20px !important;
    transition: .3s ease;
}
.services-block-four.how .inner-box .blue,
.services-block-four.how .inner-box .orange,
.services-block-four.how .inner-box .red{
    position: absolute;
    width: 60px;
    height: 100px;
    top: -33px;
    left: -10px;
    z-index: 0;
    border-radius: 0 0 15px 15px;
}
.services-block-four.how .inner-box .blue{
    background: #03A9F4;
}
.services-block-four.how .inner-box .orange{
    background: #FFC107;
}
.services-block-four.how .inner-box .red{
    background: #FF5722;
}
.services-block-four.how .inner-box h3 a{
    color: #13287e 
}
.services-block-four.how{
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
    background: #fff;
    overflow: hidden;
    border-radius: 10px;
}
@media (min-width: 1200px){
    .services-block-four.how{
        width: 100%
    }
    .services-block-four.how.mutty-l{
        margin-left: 10%
    }
}

@media (max-width: 992px){
    .service-img-wrapper.how .image-box img{
        width: 100%;
        margin-bottom: 50px
    }
}

.services-block-four .inner-box .step{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
    border: 2px solid;
    background: #7d60f9;
    border-color: #7d60f9;
    font-size: 18px;
    color: #fff;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    margin-bottom: 20px !important;
    transition: .3s ease;
}
.services-block-four.how .inner-box .text{
    margin-bottom: 0
}

.services-block-four .inner-box h3 a{
  color: #13287e;
  transition:all 0.3s ease;
  -moz-transition:all 0.3s ease;
  -webkit-transition:all 0.3s ease;
  -ms-transition:all 0.3s ease;
  -o-transition:all 0.3s ease;
}
.services-block-four .inner-box .icon-box{
    color: #25cbd3 
}

.services-block-four .inner-box h3 a:hover{
  
}

.services-block-four .inner-box .text{
  font-size:14px;
  color:#888;
  margin-top:8px;
  margin-bottom:10px;
}

.services-block-four .inner-box .read-more{
  font-weight:500;
  font-size:13px;
  text-transform:uppercase;
  transition:all 0.3s ease;
  -moz-transition:all 0.3s ease;
  -webkit-transition:all 0.3s ease;
  -ms-transition:all 0.3s ease;
  -o-transition:all 0.3s ease;
}

.services-block-four .inner-box .read-more:hover{
  color:#253267;
}
.service-img-wrapper .image-box img{
    animation: floating2 7s infinite;
    -webkit-animation: floating2 7s infinite;
}
@media (min-width: 992px){
    .service-img-wrapper .image-box{
        margin-top: -30px;
    }
}
@media (max-width: 992px){
    .service-img-wrapper .image-box img{
        width: 79%;
    }
}
@media (max-width: 767px){
    .services-block-four{
        margin-bottom: 40px
    }
}

.service-img-wrapper .image-box {
    position: relative;
}
@keyframes floating2 {
    0% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
    50% {
        -webkit-transform: rotateX(0deg) translateY(15px);
        -moz-transform: rotateX(0deg) translateY(15px);
        -ms-transform: rotateX(0deg) translateY(15px);
        -o-transform: rotateX(0deg) translateY(15px);
        transform: rotateX(0deg) translateY(15px);
    }
    100% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
}

@-webkit-keyframes floating2 {
    0% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
    50% {
        -webkit-transform: rotateX(0deg) translateY(15px);
        -moz-transform: rotateX(0deg) translateY(15px);
        -ms-transform: rotateX(0deg) translateY(15px);
        -o-transform: rotateX(0deg) translateY(15px);
        transform: rotateX(0deg) translateY(15px);
    }
    100% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
}

.demo-video.feat .services-block-four {
    padding: 25px 20px 15px;
    margin-bottom: 20px;
    background:#fff;
    border: 1px solid #eee
}
.feat .services-block-four {
    box-shadow: 0px 2px 27px 0px rgba(154, 161, 171, 0.18);
    -webkit-box-shadow: 0px 2px 27px 0px rgba(154, 161, 171, 0.18);
    padding: 30px 20px 20px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.feat .services-block-four .inner-box{
    position: relative;
    padding-left: 87px;
}
.icon-img-box{
    position: absolute;
    left: 0;
    top: 0;
}
.creative-facts{
    /* background: url(../img/bg-img/illustration.png) no-repeat center center; */
    background-size: cover
}
.c-facts-box{
    background: #fff;
    padding: 50px 30px 20px;
    border-radius: 20px;
    box-shadow: -3px 5px 50px rgba(0, 0, 0, 0.29);
}
.c-facts-box .single_cool_fact{
    background: #fff;
    text-align: left !important;
    border: none;
    margin-bottom: 50px;
    overflow: visible;
    position: relative;
    padding: 0;
    border-radius: 0
}
.c-facts-box .single_cool_fact .cool_fact_icon i{
    color: #333;
    font-size: 120px;
    font-weight: 600;
    opacity: .1;
    margin-top: -10px;
    position: absolute;
}
.c-facts-box .single_cool_fact .counter{
    color: #13287e;
    font-size: 50px;
    font-weight: 900;
}
.c-facts-box .single_cool_fact .cool_fact_detail h2{
    color: #333 !important
}

/* ##### demo-video Area CSS ##### */
.demo-video{
    position: relative;
}
.demo-video .welcome-video-area{
    margin-top: 0
}
@media (max-width: 992px){
    .vertical-social{
        display: none !important;
    }
}

.vertical-social {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background: #0d003b;
    height: 370px !important;
    margin-left: 30px;
    border-radius: 40px;
    padding: 30px 15px;
    z-index: 999;
    transition: 1s;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0px 4px 13px 0 rgba(168,67,253,.3);
    overflow: hidden;
    border-bottom: 3px solid #a843fd;
}
.vertical-social li{
    padding: 7px 0;
    text-align: center;
}
.vertical-social li a{
    color: #fff;
    opacity: .6;
    font-size: 22px;
}
.vertical-social li a:hover{
    opacity: 1
}
/* ##### trust Area CSS ##### */
.trust-section{
    position: relative;
}
.trust-item{
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 0 35px;
    padding-top: 37px;
    padding-bottom: 25px;
    box-shadow: 4px 4px 10px 0 rgba(168,67,253,.3);
    overflow: hidden;
    border-bottom: 3px solid #a843fd;
}
.ico-platform-logo{
    margin-bottom: 25px;
}
.check {
    height: 40px;
    margin: 0 -10px;
    background-color: rgba(13,0,59,.9);
    border-radius: 5px;
    color: #25cbd3;
    position: relative;
}
.check .value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 24px;
    font-weight: 600;
    text-shadow: 0 0 5px rgba(0,243,255,.5);
}
.check .check-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: url(../img/svg/checkmark.svg) 50% no-repeat;
    background-size: contain;
    width: 31px;
    height: 23px;
}

.token-distribution{
    padding-bottom: 100px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .token-info-wapper{
        margin-top: 30px;
    }
}
.token-info {
    padding: 0 12px;
    width: 100%;
    margin-bottom: 20px;
    float: left;
    display: -ms-flexbox;
    display: flex;
}
.token-info .info-wrapper {
    border: 1px solid #25cbd3;
    border-radius: 10px;
    padding: 19px 36px;
    padding-left: 65px;
    background-color: #0d003b;
    width: 100%;
    position: relative;
    -ms-flex: 1;
    flex: 1;
    display: -ms-flexbox;
    display: flex;
}
.info-wrapper .token-icon {
    left: 20px;
    width: 38px;
    height: 38px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
}
.info-wrapper .token-descr {
    display: block;
    font-size: 16px;
    color: #fff;
    padding-left: 10px;
    font-weight: 300;
    line-height: 1.25;
}
/* ##### Service Area CSS ##### */

.service_single_content {
    position: relative;
    z-index: 1;
    padding: 30px 15px;
    box-shadow: 0 5px 40px 0 rgb(0 0 0 / 11%);
    background: #fff;
    margin-bottom: 30px;
    border-radius: 15px
}

.service_single_content .service_icon i {
    font-size: 30px;
    margin-bottom: 20px;
    color: #fff;
    display: block;
}
.service_single_content .service_icon{
    margin-bottom: 20px;
    min-height: 56px;
    position: relative;
    display: inline-block;
}

.service_single_content:hover .service_icon:after{
    right: 0px;
    top: 0px;
}
.service_single_content h6 {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 17px;
}
.service_single_content p{
    margin-bottom: 0;
    color: #878484;

}
.bold{
    font-weight: 600 !important
}
.bolder{
    font-weight: 700 !important
}
.w-text{
    color: #fff !important
}
.b-text{
    color: #333 !important
}
.service_single_content.feto{
    background: #4c08bc;
    padding: 86px 15px 30px;
    border-radius: 15px;
    margin-top: 50px;
    position: relative;
}
.service_single_content.feto .service_icon{
    display: inline-block;
    position: absolute;
    top: -50px;
    left: 30px;
    box-shadow: -3px 5px 18px rgba(0, 0, 0, 0.29);
}
.service_single_content.feto .service_icon:after{
    display: none;
}
.service_single_content.feto h6{
    color: #fff;
    font-weight: bold;
    font-size: 20px
}
.service_single_content.feto p{
    color: rgba(255,255,255,.7)
}
/* ##### 6.0 CTA Area CSS ##### */

.call_to_action_area {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 500px !important;
}

.cta-content h2 {
    font-size: 40px;
    margin-bottom: 15px;
}

.cta-content p {
    font-size: 18px;
    margin-bottom: 25px;
}

.cta-content-area {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(72, 52, 212, 0.95);
    background: -webkit-linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: -webkit-linear-gradient(left, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
}
/*
* ----------------------------------------------------------------------------------------
*  what we do
* ----------------------------------------------------------------------------------------
*/

/*** 

====================================================================
    What we do
====================================================================

 ***/
.what-we-do .outer{
    position:relative;
    padding-top:0px;   
}

.what-we-do .outer .inner-circle{
    position: absolute;
    margin-left: -150px;
    top: 10%;
    left: 41%;
    z-index: -1;
    margin-top: 0px;
    width: 500px;
    height: 334px;
    line-height: 300px;
    text-align: center;
    border-radius: 50%;
}
.what-we-do .outer .phone{
    position: absolute;
    width: 147%;
    left: 50%;
    top: 10px;
    transform: translateX(-50.5%);
}

.what-we-do .outer:before{
    content:'';
    position:absolute;
    left:50%;
    margin-left: -315px;
    top: 50%;
    margin-top: -315px;
    width: 630px;
    height: 630px;
    border:1px solid #f5f5f5;
    border-radius:50%;  
}

.what-we-do .outer:after{
    content:'';
    position:absolute;
    left:50%;
    margin-left:-250px;
    top:50%;
    margin-top:-250px;
    width:500px;
    height:500px;
    border:1px solid #f5f5f5;
    border-radius:50%; 
    background: url(../img/core-img/rings.png) no-repeat center center;
     -webkit-animation: rotate-m 4s linear infinite;
     -o-animation: rotate-m 4s linear infinite;
     animation: rotate-m 4s linear infinite;
}
@-webkit-keyframes rotate-m {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-m {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.what-we-do .outer .header-wraperumn{
    float:right;    
}

.service-box-three{
    position: relative;
    z-index: 1;
    border: 2px solid #eee;
    padding: 20px 15px;
    border-radius: 10px;
    background: #fff; 
}

.what-we-do .outer .service-box-three{
    margin-bottom:130px;    
}

.what-we-do .outer .service-box-three:first-child{
    left:0px;
    top:10px;
    border-color: #8d4aff;
}

.what-we-do .outer .service-box-three:last-child{
    margin-bottom:0px;
    left:0px;
    top:0px;
    border-color: #ff7d7e;
}

.service-box-three .inner-box{
    position:relative;
    display:block;
    text-align:right;
    padding-right:90px;
}

.service-box-three .icon-box{
    position:absolute;
    right:0px;
    top:0px;
    width:72px;
    height:72px;
    text-align:center;
    line-height:72px;
    color: #fff !important;
    font-size:30px;
    border-radius:50%;
    transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -webkit-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
}

.service-box-three h3{
    position:relative;
    line-height:1.6em;
    font-size:16px;
    text-transform:uppercase;
    margin-bottom:15px;
    color:#2a2a2a;
    font-weight:700;
}

.service-box-three h3 a{
    position:relative;
    color:#13287e;
}

.service-box-three .text{
    position:relative;
    line-height:1.8em;
    color: #777
}

.service-box-four{
    position: relative;
    z-index: 1;
    border: 2px solid #eee;
    padding: 20px 15px;
    border-radius: 10px;
    background: #fff; 
}

.what-we-do .outer .service-box-four{
    margin-bottom:130px;    
}

.what-we-do .outer .service-box-four:first-child{
    right:0px;
    top:10px;
    border-color: #a9d049;
}

.what-we-do .outer .service-box-four:last-child{
    margin-bottom:0px;
    right:0px;
    top:0px;
    border-color: #46b6cf;
}

.service-box-four .inner-box{
    position:relative;
    display:block;
    text-align:left;
    padding-left:90px;
}

.service-box-four .icon-box{
    position:absolute;
    left:0px;
    top:0px;
    width:72px;
    height:72px;
    text-align:center;
    line-height:72px;
    color:#fff !important;
    font-size:30px;
    border-radius:50%;
    transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -webkit-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
}

.service-box-four h3{
    position:relative;
    line-height:1.6em;
    font-size:16px;
    text-transform:uppercase;
    margin-bottom:15px;
    color:#2a2a2a;
    font-weight:700;
}

.service-box-four h3 a{
    position:relative;
    color:#13287e;
    transition:all 300ms ease;
    -moz-transition:all 300ms ease;
    -webkit-transition:all 300ms ease;
    -ms-transition:all 300ms ease;
    -o-transition:all 300ms ease;
}

.service-box-four .text{
    position:relative;
    line-height:1.8em;
    color: #777
}
@media (min-width: 1200px){
    .outer .right-column{
        margin-left: 33.3%
    }
}
@media only screen and (max-width: 1200px){
    .what-we-do .outer .inner-circle{
        display:none;   
    }
    
    .what-we-do .outer .service-box-three:first-child,
    .what-we-do .outer .service-box-three:last-child,
    .what-we-do .outer .service-box-four:first-child,
    .what-we-do .outer .service-box-four:last-child{
        left:0px;
        top:0px;
        right:0px;
        bottom:0px;
    }
}
@media only screen and (max-width: 767px){
    .what-we-do .outer:before,
    .what-we-do .outer:after{
        display:none;   
    }
    .what-we-do .outer .service-box-three,
    .what-we-do .outer .service-box-four{
        padding: 0 20px
    }
    .what-we-do .outer .service-box-three:last-child,
    .what-we-do .outer .service-box-four:last-child,
    .what-we-do .outer .service-box-three,
    .what-we-do .outer .service-box-four{
        margin-bottom:50px; 
    }
    
    .service-box-three .inner-box,
    .service-box-four .inner-box{
        padding-left:0px;
        padding-right:0px;
        text-align:center;  
    }
    
    .service-box-three .icon-box,
    .service-box-four .icon-box{
        position:relative;
        display:block;
        left:0px;
        top:0px;
        right:0px;
        bottom:0px;
        margin:0 auto 30px; 
    }
}
.bub-left {
    background: url(../img/svg/benefits-bg.svg) no-repeat center left;
}
.bub-right {
    background: url(../img/svg/bg_hero.svg) no-repeat center right;
}
.ring-bg {
    background: url(../img/bg-img/ring-bg.png) no-repeat center center;
    background-size: cover;
}
.hex-pat-1{
    background: url('../img/svg/hex-pattern.svg') no-repeat center left;
}
.hex-pat-2{
    position: relative;
}
.hex-pat-2:before{
    display: none;
}
@media (min-width: 1200px){
    
    .hex-pat-2:before{
        position: absolute;
        right: 0;
        display: block;
        content: "";
        top: 20%;
        width: 400px;
        height: 400px;
        background: url('../img/svg/hex-pattern2.svg') no-repeat center right;
    }
}
.sh-bg {
    /* background: url(../img/svg/sh-bg.svg) no-repeat center center; */
    background-size: cover;
}
.st-bg {
    /* background: url(../img/svg/striples-bg.svg) no-repeat top center; */
    background-size: cover;
}

/*
* ----------------------------------------------------------------------------------------
*  START Roadmap STYLE
* ----------------------------------------------------------------------------------------
*/
.timeline-split{
    position: relative;
    width: 100%
}
.timeline-split .timeline {
   position: relative;
   padding: 50px;
   overflow: hidden
}
.timeline-split .timeline h3{
    font-size: 22px;
    color: #25cbd3
}
.timeline-split .timeline span{
    color: #666;
    display: block;
    margin-bottom: 10px
}
/*top circle */
.timeline-split .timeline::before {
   content: "";
   width: 20px;
   height: 20px;
   border-radius: 50%;
   display: block;
   position: absolute;
   top: 0;
   left: 50%;
   background: #25cbd3;
   left: calc(50% - 10px);
}
/*vertival line */
.timeline-split .timeline::after {
   content: "";
   width: 2px;
   height: 4000px;
   display: block;
   position: absolute;
   top: 10px;
   left: 50%;
   left: calc(50% - 1px);
   background: #25cbd3;
}

/*timeline block */
.timeline-split .timeline .block {
   width: 50%;
    padding: 30px;
    background: #0d003b;
    border: 1px solid #25cbd3;
    width: calc(50% - 74px);
    text-align: left;
    position: relative;
}
.timeline .block .date{
    padding: 5px 10px;
    display: inline-block;
    background: #25cbd3;
    margin: 10px 0;
    color: #fff;
    font-size: 13px;
    border-radius: 15px;
}
.timeline .block .between{
    padding: 5px 10px;
    display: inline-block;
    color: #fff;
}
/*block marker */
.timeline-split .timeline .block::before {
   content: "";
   width: 10px;
   height: 10px;
   border-radius: 50%;
   position: absolute;
   background: #25cbd3;
   top: 30px;
}
.timeline-split .timeline .block.block-left::after,
.timeline-split .timeline .block.block-right::after {
   content: "";
    width: 95px;
    height: 2px;
    position: absolute;
    background: #25cbd3;
    top: 34px;
    z-index: -1;
    right: -78px;
}
.timeline-split .timeline .block.block-right::after{
    left: -80px
}
/*left block */
.timeline-split .timeline .block.block-left {
   text-align: right;
}
.timeline-split .timeline .block.block-left::before {
   right: -80px;
}
.timeline .block p{
    margin-bottom: 0
}
/*right block */
.timeline-split .timeline .block.block-right {
   text-align: left;
   margin-left: 50%;
   margin-left: calc(50% + 74px);
}
.timeline-split .timeline .block.block-right::before {
   left: -80px;
}
/*decrease the timeline heading text */
@media (max-width: 992px) {
   .timeline-split .timeline {
      padding: 50px 20px;
   }

   .timeline-split .timeline h3 {
      font-size: 19px;
   }
}
/*change timeline layout to fit tiny screen size */
@media (max-width: 992px) {
    .timeline-split .timeline .block.block-left::after {
        left: -80px;
    }
   .timeline-split .timeline::after {
      left: 9px;
   }
   .timeline-split .timeline::before {
      left: 0;
   }
   .timeline-split .timeline .circle {
      left: 2px;
   }
   .timeline-split .timeline .block {
      width: 100% !important;
      text-align: left;
      padding-left: 20px;
   }
   .timeline-split .timeline .block::before {
      left: -15px;
   }
   .timeline-split .timeline .block.block-left {
      text-align: left;
   }
   .timeline-split .timeline .block.block-right {
      margin-left: 0;
   }
   .timeline-split .timeline .block.block-right::before {
      left: -15px;
   }
   .mt-s{
    margin-top: 30px
   }
}

/* ##### 7.0 Video Area CSS ##### */

.mfp-wrap {
    z-index: 6000;
}

.mfp-bg {
    z-index: 5500;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    right: 0;
    text-align: center;
    display: inline-block;
    width: 40px;
    height: 40px;
    color: #fff;
    line-height: 40px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    padding-right: 0;
}

.mfp-iframe-holder .mfp-close {
    top: 0;
}

/* ##### 9.0 Gallry Item Area CSS ##### */

.single_gallery_item {
    position: relative;
    z-index: 1;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-bottom: 30px;
}

.gallery-hover-overlay {
    position: absolute;
    width: calc(100% - 30px);
    height: 100%;
    top: 0;
    left: 15px;
    z-index: 10;
    background-color: rgba(72, 52, 212, 0.8);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 30px;
    text-align: center;
}

.single_gallery_item:hover .gallery-hover-overlay {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
}

.portfolio-menu button {
    line-height: 1;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    text-transform: uppercase;
    padding: 8px 15px 5px;
    border-radius: 30px;
}

.portfolio-menu button.active {
    color: #fff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.port-more-view > a {
    color: #fff;
}

.port-hover-text h3 {
    font-size: 14px;
    margin-bottom: 0;
}

.port-hover-text > a {
    font-size: 12px;
    color: #fff;
    text-transform: capitalize;
}

/* ##### 10.0 Cool Fact Area CSS ##### */

.cool-facts-area {
    padding: 100px 0 70px;

}

.single_cool_fact {
    position: relative;
    z-index: 1;
    background: #fff;
    border: 1px solid #25cbd3;
    border-bottom: 3px solid #25cbd3;
    border-radius: 0 0 20px 20px;
    padding: 30px;
    overflow: hidden;
    margin-bottom: 30px;
}


.cool_fact_icon i {
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .1;
    color: #242020;
    font-weight: 800;
}

.cool_fact_detail h3 {
    font-size: 35px;
    font-weight: 600;
    margin-top: 0 !important;
    margin-top: 15px;
    color: #13287e !important;
}

.cool_fact_detail h2 {
    font-size: 14px;
    margin-bottom: 0;
    color: #13287e !important;
    text-transform: uppercase;
}

/* ##### 11.0 Price Table Area CSS ##### */

.single_price_table_content {
    padding: 0 0px 40px;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;
    border-radius: 6px;
    box-shadow: 0 5px 40px 0 rgb(0 0 0 / 11%);
}

.single_price_table_content.active {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
}

.price_table_text {
    margin-bottom: 20px;
    padding: 40px 30px;
    text-align: right;
    background: url(../img/core-img/price.png) no-repeat top right;
    background-size: 75%;
}
.free-7{
    border: 1px solid #1d88ed;
    text-align: center;
    padding: 15px
}
.free-7 span{color: #1d88ed; font-weight: 600;}
.free-7 p{color: #1d88ed !important; margin-bottom: 0;}
.price_table_text > h5 {
    font-size: 16px;
    margin-bottom: 20px;
}
.table_text_details{
    padding: 0 30px
}
.table_text_details h3{
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
}
.table_text_details h3 span{
    font-size: 14px;
    background: #e8f3fd;
    padding: 5px 15px;
    position: relative;
    top: -5px;
    border-radius: 30px;
    color: #1d88ed;
}
.price_table_text > h1 {
    font-size: 40px;
    font-weight: 700;
    color: #fff !important;
    margin-bottom: 10px;
    line-height: 1;
}

.price_table_text > p,
.table_text_details > p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
}

.table_text_details > p {
    margin-bottom: 20px;
}
.mb-0{margin-bottom: 0 !important}
/* ##### 12.0 testimonial Area CSS ##### */
/* ##### 12.0 testimonial Area CSS ##### */
.single-testimonial{
    border-radius: 15px;
    border: 1px solid #eee;
    position: relative;
    margin: 0 15px;
    overflow: hidden;
    padding: 30px 20px;
    /* background: url(../img/core-img/cards-bg.png), #fff; */
}
.icon_wrapper:after {
    content: '';
    border-right: 100px solid transparent;
    border-top: 100px solid #3e94e4;
    position: absolute;
    left: 0px;
    top: 0px;
}
.icon_wrapper img{
    top: 20px;
    left: 8px;
    max-width: 41px;
    color: #fff;
    z-index: 3;
    position: absolute;
}
.icon_wrapper i {
    top: 20px;
    left: 15px;
    color: #fff;
    font-size: 36px;
    z-index: 3;
    position: absolute;
}

.icon_foot:before {
    content: '';
    border-left: 80px solid transparent;
    border-bottom: 80px solid #fdc236;
    position: absolute;
    right: 0px;
    bottom: 0px;
}
.icon_foot img{
    top: 20px;
    left: 12px;
    max-width: 41px;
    color: #fff;
    z-index: 3;
    position: absolute;
}
.icon_foot i {
    bottom: 12px;
    right: 8px;
    color: #fff;
    font-size: 28px;
    z-index: 3;
    position: absolute;
    transition: all .4s ease-in-out;
}
.icon_foot:hover i{
    right: 5px;
}
.testimonial-description {
    position: relative;
    z-index: 2;
    background-color: transparent;
    text-align: center;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

.testimonial_image {
    height: 100px;
    width: 100px;
    margin: auto;
}

.testimonial_image > img {
    border-radius: 50%;
}

.testimonial_text > p {
    font-weight: 500;
    color: #878484;
    margin-top: 20px;
    margin-bottom: 25px;
    font-size: 14px;
}

.admin_text > h5 {
    font-size: 20px;
    font-weight: 600;
    color:  #878484;
    margin-top: 0;
    margin-bottom: 5px;
}

.admin_text > p {
    color: #878484;
    font-weight: 500;
    margin-bottom: 0;
}

.client_slides .owl-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 50px;
}

.client_slides .owl-dot {
    margin: 0 5px;
    line-height: 1;
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.client_slides .owl-dot.active {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.15);
}

/* ##### 13.0 Team Area CSS ##### */

.single-team-member {
    position: relative;
    z-index: 1;
    padding: 0 30px;
    margin-bottom: 100px;
}

.team-member-thumb {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    margin:0 auto;
    margin-bottom: 20px;
}

.team-info h5 {
    color: #7057f9;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0px;
}

.team-info p {
    color: #a592b4;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 0;
    text-align: center;
}
.team-social-icon{
    text-align: center;
}
.team-social-icon a {
    font-size: 15px;
    margin-top: 10px;
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px
}
/*
* ----------------------------------------------------------------------------------------
*  START Subscribe STYLE
* ----------------------------------------------------------------------------------------
*/
.subscribe{
    position: relative;
    padding: 50px 20px;
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 5px 40px 0 rgb(0 0 0 / 11%);
}
.custom{
    position: absolute;
    left: -5px;
    width: 300px;
}
@media (max-width: 992px){
    .custom{display: none;}
}
.subscribe .section-heading > p{
    color: #eee
}

.subscribe .section-heading{
    margin-bottom: 30px
}

.subscribe .service-text{
    padding-top: 0
}
.subscribe .title-box{
    margin-bottom: 30px
}

.subscribe-wrapper {
    padding: 60px;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 20px;
    background: #1d66f6;
    box-shadow: 0 10px 80px rgba(15,62,153,.3);
}

.telegram-text{
    font-size: 20px;
    color: #fff;
    margin-right: 10px;
    position: relative;
    z-index: 2
}

.social-list{
    display: inline-block;
    margin-bottom: 0;
    height: 40px;
    position: relative;
    z-index: 2;
}
.social-list li{
    float: left;
    padding: 0 14px
}
.social-list li a{
    font-size: 34px;
    color: #fff;
    opacity: .8
}
.social-list li a:hover{
    opacity: 1
}
.subscribe .buy-tokens i{
    margin-right: 10px
}
.subscribe .dream-btn{
    position: absolute;
    top: -2px;
    right: -10px;
    min-width: 48px;
    height: 48px;
    padding: 0;
    border-color: #fff;
}
.subscribe .dream-btn:hover{
    cursor: pointer;
}
.button {
    position: relative;
    z-index: 0;
    overflow: hidden;
    display: inline-block;
    padding: 14px 40px;
    font-size: 16px;
    z-index: 2;
    line-height: 1.25;
    color: #1d88ed;
    background: #fff;
    border: 2px solid #1d88ed;
    border-radius: 5px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    user-select: none;
    transition: 250ms all ease-in-out;
}
.button:hover{
    background: #25cbd3;
    color: #fff
}
.button i{
    margin-right: 10px
}



/* ##### 14.0 Our Blog Area CSS ##### */

.single-blog-area {
    position: relative;
    z-index: 1;
    margin-bottom: 100px;
}

.post-meta p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    text-transform: uppercase;
}

.post-meta p a {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    margin-right: 30px;
}

.post-title {
    margin-bottom: 20px;
    display: block;
}

.blog_thumbnail img {
    width: 100%;
}

.single-blog-area blockquote {
    background-color: rgba(16, 106, 241, 0.1);
    padding: 30px;
    border-radius: 3px;
    margin: 30px 0;
    display: block;
}

.single-blog-area blockquote span {
    margin-bottom: 0;
    color: #2418d6;
    font-size: 12px;
    text-transform: uppercase;
}

.comment_area {
    border-top: 2px solid rgba(20, 34, 210, 0.41);
    border-bottom: 2px solid rgba(20, 34, 210, 0.41);
    padding-top: 50px;
    padding-bottom: 50px;
}

.comment_area .title {
    margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
    .comment_area .comment-content {
        padding: 20px 15px;
    }
    .welcome-content h1{font-size: 34px}
}

.comment_area .comment-content .comment-author {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 79px;
    flex: 0 0 79px;
    min-width: 79px;
    margin-right: 55px;
    border-radius: 50%;
}

@media only screen and (max-width: 767px) {
    .comment_area .comment-content .comment-author {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 60px;
        flex: 0 0 60px;
        min-width: 60px;
        margin-right: 15px;
    }
}

.comment_area .comment-content .comment-author img {
    border-radius: 50%;
}

.comment_area .comment-content .comment-meta {
    margin-bottom: 30px;
}

.comment_area .comment-content .comment-meta .post-date {
    color: #1953f5;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 0;
    display: block;
}

.comment_area .comment-content .comment-meta .post-author {
    margin-bottom: 15px;
    display: block;
    color: #333;
}

.comment_area .comment-content .comment-meta p {
    margin-bottom: 15px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 2;
}

.comment_area .comment-content .comment-meta .comment-reply {
    font-size: 11px;
    color: #1953f5;
    text-transform: uppercase;
}

.comment_area .single_comment_area {
    margin-bottom: 30px;
}

.comment_area .single_comment_area:last-of-type {
    margin-bottom: 0;
}

.comment_area .children .single_comment_area {
    margin-left: 50px;
    margin-top: 30px;
}

@media only screen and (max-width: 767px) {
    .comment_area .children .single_comment_area {
        margin-left: 15px;
    }
}

.single_comment_area .children .single_comment_area .comment-meta {
    margin-bottom: 0;
}


/* ##### Contact Area CSS ##### */

.group {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}

.group input,
.group textarea {
    font-size: 12px;
    font-style: italic;
    padding: 10px;
    display: block;
    width: 100%;
    height: 45px;
    border: none;
    background-color: transparent;
    color: #575454;
    border-radius: 0;
    border-bottom: 2px solid rgba(96, 93, 93, 0.2);
    
}

.group input:focus,
.group textarea:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    
}

.group label {
    color: #585757;
    font-style: italic;
    font-size: 12px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 14px;
    -webkit-transition: 0.5s ease all;
    transition: 0.5s ease all;
    margin-bottom: 0;
}

.group .bar {
    position: relative;
    display: block;
    width: 100%;
}

.group .bar:before,
.group .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background-color: #666464;
    -webkit-transition: 0.5s ease all;
    transition: 0.5s ease all;
}

.group .bar:before {
    left: 50%;
}

.group .bar:after {
    right: 50%;
}

.group textarea {
    height: 130px;
}

/* Form Active State */

.group input:focus ~ label,
.group textarea:focus ~ label,
.group input:valid ~ label,
.group textarea:valid ~ label {
    top: -17px;
    font-size: 12px;
    color: #565454;
}

.group input:focus ~ .bar:before,
.group textarea:focus ~ .bar:before,
.group input:focus ~ .bar:after,
.group textarea:focus ~ .bar:after {
    width: 50%;
    background-color: #585454;
}

input:required,
textarea:required {
    box-shadow: none !important;
    
}

/* ##### Footer Area ##### */

.footer-area {
    position: relative;
    z-index: 1;
    padding: 0;
    background: url(../img/core-img/footer-bg1.png) no-repeat;
    background-size: cover;
}
.footer-content-area{
    padding: 80px 0;
    margin-top: 100px
}
.footer-logo{
    margin-bottom: 15px
}
.footer-logo a{
    color: #fff;
    font-size: 20px
}
.footer-logo img{
    width: 40px
}
.footer-side-thumbnail {
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
    height: 100%;
    background-size: cover;
    background-position: top center;
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
}

.copywrite_text > p {
    margin-bottom: 10px;
    color: #fff!important;
    font-size: 13px;
    color: #d3cdcd
}

.copywrite_text > p > a {
    color: #fff;
}

.footer-social-info a i {
    font-size: 14px;
    color: #fff;
    margin-right: 15px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

.footer-social-info a i:hover {
    color: #4a7aec;
}

.contact_info_area .contact_info {
    text-align: left !important;
}

.contact_info_area .contact_info h5 {
    font-size: 14px;
    color: #fff
}

.contact_info_area .contact_info p {
    margin-bottom: 0;
    font-size: 14px;
    color: #d3cdcd
}
.contact_info_area .contact_info a:hover p{
    color: #fff
}

/* ##### Breadcumb Area ##### */

.breadcumb-area {
    position: relative;
    z-index: 1;
    height: 400px !important;
}

.breadcumb-content {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background: url(../img/bg-img/bread.jpg) repeat;
}

.breadcumb--con {
    padding-top: 90px;
}

.breadcrumb {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
}

.breadcumb--con .title {
    font-size: 42px;
    margin-bottom: 15px;
    margin-left: 0;
}

.breadcrumb-item,
.breadcrumb-item.active,
.breadcrumb-item > a {
    font-size: 13px;
    text-transform: uppercase;
    color: #fff;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-link {
    color: #333;
    background-color: transparent;
    border: 1px solid #1928e0;
    margin: 0 2px;
    width: 40px;
    text-align: center;
}

/* ##### Sidebar CSS ##### */

.search-widget-area form {
    position: relative;
    z-index: 1;
}

.search-widget-area form input {
    width: 100%;
    height: 45px;
    border: 2px solid rgb(25, 32, 87);
    background-color: transparent;
    padding: 0 15px;
    color: #fff;
    font-size: 12px;
    border-radius: 30px;
}

.search-widget-area form button {
    width: 60px;
    height: 45px;
    background-color: transparent;
    padding: 0 15px;
    color: #fff;
    font-size: 14px;
    border-radius: 30px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
}

.widget-title h5 {
    margin-bottom: 30px;
    font-size: 18px;
    text-transform: capitalize;
    border-bottom: 2px solid #1953f5;
    padding: 0 0 5px 0;
}

.dont-miss-post-content > a {
    font-size: 16px;
    color: #333;
    display: block;
    margin-top: 15px;
}
.width-100{
    width: 100%
}

#wrap {
  width: 730px;
  height: 940px;
  padding: 0;
  overflow: hidden;
}

#scaled-frame {
  width: 1000px;
  height: 1260px;
  border: 0px;
}

#scaled-frame {
  zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  #scaled-frame {
    zoom: 1;
  }
}
.dont-miss-post-content > span {
    font-size: 12px;
    color: #1953f5;
    display: block;
    text-transform: uppercase;
}

.subscribe-form input {
    width: 100%;
    height: 45px;
    border-radius: 45px;
    border: 1px solid rgb(25, 32, 87);
    padding: 0 20px;
    font-size: 12px;
    font-style: italic;
    color: #fff;
    margin-bottom: 15px;
    background-color: rgba(255, 255, 255, 0.1);
}

.subscribe-form button {
    width: 100%;
    height: 45px;
    border-radius: 45px;
    border: none;
    font-size: 12px;
    padding: 0;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 15px;
    background-color: rgb(62 148 228);
    cursor: pointer;
}
.temp-summary,
.subscribe-widget{
    border: 1px solid #eee;
    box-shadow: 0px 2px 27px 0px rgb(154 161 171 / 18%);
    padding: 30px 20px;
    
}
.temp-img{
    border: 1px solid #eee;
    box-shadow: 0px 2px 27px 0px rgb(154 161 171 / 18%);
}
/* ##### Timeline CSS ##### */

.timelineBox {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 2px;
}

.timelineHeader {
    padding: 32px;
    padding: 32px;
    padding: 2rem;
    background: #e91e63;
    position: relative;
    z-index: 3;
}

.timelineHeader h3 {
    font-size: 32px;
    font-size: 32px;
    font-size: 2rem;
    margin: 0;
}

.timelineHeader h3 + span {
    font-size: 19.2px;
    font-size: 19.2px;
    font-size: 1.2rem;
    color: #fff;
}

.timelineBody {
    max-height: 480px;
    overflow-x: hidden;
    overflow-y: auto;
}

.timelineBody .timeline {
    padding: 2em;
    margin: 0;
    list-style: none;
    position: relative;
    z-index: 2;
}

.timelineBody .timeline li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 1em 0;
    position: relative;
}
.timelineBody .timeline li:before{
    position: absolute;
    content: '';
    left: 7px;
    top: 0;
    width: 1px;
    height: 100%;
    background: #25cbd3
}

.timelineBody .timeline li:last-child {
    margin-bottom: 0;
}

.timelineBody .timeline .timelineDot {
    height: 15px;
    width: 15px;
    background: #fff;
    border-radius: 2rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15px;
    flex: 0 0 15px;
    margin-right: auto;
    margin-top: 6px;
    box-shadow: 0 0 8px #56cb5f;
}

.timelineBody .timeline .timelineDot:after {
    content: '';
    position: absolute;
    top: 25px;
    left: 3px;
    height: 9px;
    width: 9px;
    background: #25cbd3;
    border-radius: 50%;
}

.timelineBody .timeline .timelineDate {
    font-size: 14px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    padding: 0;
}

.timelineBody .timeline .timelineDate p {
    color: #25cbd3;
}

.timelineBody .timeline .timelineWork {
    font-size: 16px;
    margin-left: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
}

.timelineBody .timeline .timelineWork h6 {
    color: #fff;
}

.timelineBody .timeline .timelineWork span {
    display: block;
    color: #bdbdbd;
    font-size: 13px;
}
/*
* ----------------------------------------------------------------------------------------
*  START counter-down STYLE
* ----------------------------------------------------------------------------------------
*/
.ico-counter{
    background-image: linear-gradient(to right, #21d397 0%, #7450fe 100%);
    padding: 40px;
    border-radius: 20px
}

@media (max-width: 767px) {
    .ico-counter{
        margin-top: 30px;
    }
}
.counter-down{
    position: relative;
}
.conuter-header{
    overflow: hidden;
    position: relative;
}

.timer-body-block{
    display: flex
}
.count-down .table-cell {
    position: relative;
    width: 25%
}
.count-down .tab-val {
    width: 90%;
    font-size: 30px;
    font-weight: 500;
    height: 75px;
    line-height: 75px;
    margin: 0 auto;
    background-color: #190345;
    color: #ffffff;
}
.count-down .tab-metr {
    margin-top: 15px;
    font-size: 16px;
    color: #ffffff;
}

.conuter-header h3{
    font-weight: 600;
    font-size: 24px;
    color: #fff
}
.conuter-header h4{
    font-size: 18px;
    text-transform: uppercase;
}
.counterdown-content{
    padding: 30px 0 0;

}
.clock-wrapper{
    position: relative;
    background: #fff;
    padding: 30px 0 15px 9px;
    border-radius: 14px;
    overflow: hidden;
    border: 1px solid
}
.dollar-earning{
    font-size: 24px;
    font-weight: 600;
    padding-left: 10px;
    color: #fff;
}
.btc-earning{
    font-size: 24px;
    font-weight: 600;
    padding-right: 45px;
    color: #fff;
    position: relative;
}
.ico-sales-status{
    overflow: hidden;
}
.ico-sales-status p{
    font-size: 12px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.62)
}
.btc-earning span{
    font-size: 13px;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 6px;
}

.ico-progress {
   margin: 20px 10px;
}
.ico-progress ul{
    margin-bottom: 5px
}
.ico-progress li {
   font-size: 18px;
   font-weight: 400;
}
.ico-progress li.title {
   float: left;
   padding-left: 30px;
   font-weight: 500;
   color: #fff
}
.ico-progress li.strength {
   float: right;
   font-weight: 500;
   color: #fff
}
.ico-progress .current-progress {
   width: 100%;
   height: 16px;
   position: relative;
   background: rgba(191, 191, 191, .6);
   border-radius: 7px
}
.ico-progress .current-progress:before{
    content: '';
    position: absolute;
    width: 1px;
    height: 26px;
    bottom: -5px;
    left: 12%;
    background: #fff
}

.current-progress .progress-bar{
    border-radius: 7px;
    height: 100%;
    background: linear-gradient(to right, #4834d4, #341f97)
}
.ico-progress span {
    color: #370779;
    font-size: 12px;
    font-weight: 700;
    padding-top: 7px;
    display: inline-block;
}
.doc-element{
    background-color: #1d025c;
    border-radius: 4px;
    border-bottom: 2px solid #25cbd3;
    position: relative;
    transition: .5s;
    cursor: pointer;
    padding: 20px;
    margin-top: 20px;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.doc-element:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    transition: .3s;
    right: 15px;
    width: 27px;
    height: 34px;
    /* background: url(../img/svg/pdf.svg) 50% no-repeat; */
    background-size: contain;
}
.doc-element:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    transition: .3s;
    right: 15px;
    width: 27px;
    height: 30px;
    /* background: url(../img/svg/view.svg) 50% no-repeat; */
    background-size: contain;
    opacity: 0;
}
.doc-element .document-entry .title {
    font-size: 14px;
    color: #fff;
    line-height: 1.35;
}
.doc-element:hover {
    background-color: #25cbd3;
}
.doc-element:hover:after {
    opacity: 1;
}
/* ##### Accordians CSS ##### */

.dream-faq-area dl {
    width: 100%;
}

.dream-faq-area dt {
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 0;
    color: #fff;
    font-weight: 500;
}

.dream-faq-area dt:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.dream-faq-area dd {
    margin: 0;
    padding: 15px 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* ##### Button Effects ##### */

.ripple {
    position: absolute;
    height: .25em;
    width: .25em;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    z-index: -1;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    -webkit-transform-origin: center 50%;
    transform-origin: center 50%;
    -webkit-transition: opacity 1.6s;
    transition: opacity 1.6s;
    -webkit-animation: ripple 1.6s;
    animation: ripple 1.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.ripple-active {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

@-webkit-keyframes ripple {
    0% {
        -webkit-transform: scale3d(0, 0, 0);
        transform: scale3d(0, 0, 0);
    }
    100% {
        -webkit-transform: scale3d(50, 50, 50);
        transform: scale3d(50, 50, 50);
    }
}

@keyframes ripple {
    0% {
        -webkit-transform: scale3d(0, 0, 0);
        transform: scale3d(0, 0, 0);
    }
    100% {
        -webkit-transform: scale3d(50, 50, 50);
        transform: scale3d(50, 50, 50);
    }
}

/* ##### Animated Headline CSS ##### */

.cd-intro.default-title > h2 {
    font-size: 50px;
}

.cd-headline.clip b,
.cd-words-wrapper b {
    font-weight: 500;
}

.cd-headline {
    font-weight: 500;
}

/* ##### particless CSS ##### */

#particles-js {
    height: 900px;
    width: 100%;
    position: relative;
    z-index: 1;
}

#particles-js canvas.particles-js-canvas-el {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}

/* ##### Snow Version CSS ##### */

.snow-content-text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
}

.fullwidth {
    width: 100vw !important;
    height: 100vh !important;
}
@media only screen and (max-width: 991px) and (min-width: 768px){
    .welcome_area.ico{
        height: 1200px !important
    }
}
/* ##### Slider Area CSS ##### */

.welcome_area .welcome_slides .owl-prev,
.welcome_area .welcome_slides .owl-next {
    color: #fff;
    font-size: 24px;
    left: -100px;
    margin-top: -44px;
    position: absolute;
    text-align: center;
    top: calc(50% + 90px);
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    z-index: 99;
}

.welcome_area .welcome_slides .owl-next {
    right: -100px;
    left: auto;
}

.welcome_area .welcome_slides:hover .owl-next {
    right: 30px;
}

.welcome_area .welcome_slides:hover .owl-prev {
    left: 30px;
}

/* ##### Flying Bird CSS ##### */

.bird {
    background-size: cover;
    width: 352px;
    height: 500px;
    -webkit-animation: fly-cycle 1s -0.5s steps(10) infinite;
            animation: fly-cycle 1s -0.5s steps(10) infinite;
}

@-webkit-keyframes fly-cycle {
    100% {
        background-position: -3600px 0;
    }
}

@keyframes fly-cycle {
    100% {
        background-position: -3600px 0;
    }
}

.bird {
    position: absolute;
    top: 23%;
    left: 10%;
    z-index: -1;
}

/* ##### Subscribe form area CSS ##### */

.welcome-content .subscribe-email {
    width: 70%;
    height: 55px;
    border: none;
    padding: 0 25px;
    border-radius: 8px;
    font-size: 12px;
    font-style: italic;
}

/* ##### Light Version CSS ##### */

body.light-version {
    background: #fff !important;
}

.light-version p {
    color: #888;
}

.light-version h1,
.light-version h2,
.light-version h3,
.light-version h4,
.light-version h5,
.light-version h6,
.light-version .growing-company p .counter,
.light-version .service_single_content .service_icon i,
.light-version .portfolio-menu button,
.light-version .post-meta p a,
.light-version .group label,
.light-version input:focus ~ label,
.light-version textarea:focus ~ label,
.light-version input:valid ~ label,
.light-version textarea:valid ~ label,
.light-version .copywrite_text > p > a {
    color: #13287e ;
}

.light-version .dream-faq-area dt {
    color: #fff;
    border: 1px solid #f2f4f8;
    background-image: linear-gradient(to right, #21d397 0%, #7450fe 100%);
    background-image: -webkit-linear-gradient(to right, #21d397 0%, #7450fe 100%);
    border-radius: 25px;
    margin-bottom: 15px;
}

.light-version .dream-faq-area dt:first-child {
    border-top: 1px solid #f2f4f8;
}

.light-version .timelineBox {
    background: #f2f4f8;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.light-version .single-mission h6,
.light-version .single-mission p,
.light-version .welcome-content h2,
.light-version .cool_fact_detail h3,
.light-version .cool_fact_detail h2,
.light-version .cta-content h2,
.light-version .cta-content p {
    color: #fff;
}

.light-version .portfolio-menu button.active {
    color: #13287e ;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.light-version .single-team-member:after {
    position: absolute;
    width: 50%;
    height: 105%;
    top: 0;
    border-radius: 20px;
    left: 0;
    background-color: #f2f4f8;
    content: "";
    z-index: -5;
}

.light-version #scrollUp {
    color: #000;
}

.light-version #scrollUp:before {
    position: absolute;
    width: 30%;
    height: 2px;
    background-color: #000;
    content: "";
    top: 10px;
    right: 100%;
    z-index: -200 !important;
}

.light-version .group input,
.light-version .group textarea {
    color: #13287e  !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}
.subscribe .group input{
    color: #fff !important
}
.subscribe .group label{
    color: #fff !important
}
.light-version .group input:focus ~ .bar:before,
.light-version .group textarea:focus ~ .bar:before,
.light-version .group input:focus ~ .bar:after,
.light-version .group textarea:focus ~ .bar:after {
    background-color: #13287e ;
}

.light-version .client_slides .owl-dot {
    color: #13287e ;
    background-color: transparent;
}

.light-version .client_slides .owl-dot.active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}


/* demo page */
.demo-item {
    -webkit-box-shadow: 0 2px 28px rgba(0,0,0,.1);
    box-shadow: 0 2px 28px rgba(0,0,0,.1);
    transition: all .3s ease-in;   
    overflow: hidden;
    background: #fff;
    margin-bottom: 30px;
    border: 2px solid transparent;
}
@media (min-width: 1200px){
    .demo .container {
        max-width: 1280px;
    }
}
.demo-item:hover{
    -webkit-box-shadow: 0 0 2rem -0.5rem #e44747;
    box-shadow: 0 0 2rem -0.5rem #e44747;
    border: 2px solid #e44747
}

.preview-link{text-align: center;}
.preview-demo{
    margin: 20px 5px;
    background: #1d88ed;
    display: inline-block;
    padding: 15px 20px;
    width: 44%;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    border-radius: 100px;
    text-transform: uppercase;
}
.preview-demo.v2{
    color: #1d88ed;
    background: #fff;
    border: 2px solid #1d88ed;
}
.preview-demo i{
    margin-left: 10px
}
.preview-demo:hover{
    color: #13287e 
}

.block-container,
.all-edus,
.all-exps{
    border: 1px solid #eee;
    box-shadow: 0px 2px 27px 0px rgb(154 161 171 / 18%);
    padding: 20px 20px 30px;
    margin-bottom: 30px;
    background: #fff;
}
.topnote{
    color: #3c3939;
    margin-bottom: 0;
    font-size: 14px;
}
.cv-download{
    position: fixed;
    z-index: 99;
    top: 0;
    padding: 30px 20px;
    background: #fff;
    width: 57%;
    box-shadow: 0 3px 15px rgb(0 0 0 / 10%);
    border-left: 5px solid #007bff;
}

@media (max-width: 767px){
    .cv-download{
        position: relative;
        top: 30px;
        width: 100%;
    }
}
.btn{
    border: 2px solid #007bff
}

label{
        margin-top: 14px;
        margin-bottom: 0px;
    }  
    hr{border-width: 2px; margin: 42px 0;}
    .fa-plus-circle{
        color: #267DDF;
        display: inline-block;
        font-size: 100px;
        cursor: pointer;
        float: left;
        margin-left: 6px;
    }
    .tags{width: 50%;}
    .tags .tag-span{
        padding: 5px 8px;
        background: #ccc;
        margin: 8px 5px 5px 0px;
        display: inline-block;
        border-radius: 4px;
    }

    .tags .tag-span i{
        cursor: pointer;
    }
    h2{
        color: #09229c
    }
    .add-skills #input-skills,
    .add-skills #input-skills-percent{
        width: 40%;
        margin-left: 10px;
        display: inline-block;
        float: left;
    }
    .add-blk{
        overflow: hidden;
        float: right;
        margin: 0px;
    }
    
    .add-blk .fa-plus{
        float: right;
        font-size: 29px;
        color:#e0e0e0;
        margin-right: 4px;
    }
    .add-blk span{
        float: right;
        margin: 2px 7px;
        font-weight: bold;
    }
    .add-langs .new-lang{margin-bottom: 30px}
    .add-border{
        margin: 30px auto;
        width: 60%;
        text-align: center;
    }
    .add-border span{
        height: 1px;
        width: 200px;
        background: #00B7C2;
        display: inline-block;
    }
    .add-border h2{
        display: block;
        font-size: 21px;
        padding: 0px 11px;
        margin-top: 10px;
        position: relative;
        top: 7px;
    }
    
    /*********/
    
    .btn-sub{
        position: relative;
        margin: 37px auto;
        height: 50px;
        display: block;
        border-radius:5px;
        border: none;
        background-color: #007bff;
        color:#fff;
        font-size: 23px;
        padding: 11px 40px;
        box-shadow: 0 5px 0 #345880;
    }
    .btn-sub:hover{
        top: 2px;
        box-shadow: 0 3px 0 #345880;
        cursor: pointer;
    }
    .btn-sub:active{
        top:5px;
        outline: 0;
        box-shadow: none;
    }
    
    /*********/
    .ali-bg{
        background-color: aliceblue !important
    }

    .header-link{
        text-transform: uppercase;
        /* font-size: 80%; */
        font-weight: 600 !important;
        margin-right: 20px;
        
    }

    .header-area{
        background-color: #ccd7e8;

    }